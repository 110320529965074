import * as React from "react"
import SvgIcon from '@mui/material/SvgIcon';

type IIconProps = {
  className?: string
  width?: number
  height?: number
}

export const TestingToolIcon = ({ className, width, height }: IIconProps, ...props: any) => 
  <SvgIcon 
    width={width || 30}
    height={height || 30}
    viewBox="0 0 31.93 31.93"
    fill="#000000"
    className={className}
    {...props}
  >
    <path d="M29.232 23.504c-.381-.674-1.271-2.258-2.188-3.609a33.964 33.964 0 0 0-.692-.973H11.921c-.224.305-.456.627-.691.973-.919 1.352-1.809 2.936-2.188 3.609l-.102.182c-.391.69-.396 1.632-.012 2.291.292.498.757.774 1.313.774h17.79c.555 0 1.021-.276 1.314-.774.381-.659.377-1.601-.016-2.291l-.097-.182z" />
    <path d="m31.426 23.346-.173-.304c-.647-1.151-2.159-3.845-3.722-6.143-.523-.77-1.051-1.49-1.528-2.141-.765-1.042-1.716-2.337-1.726-2.722.059-1.689.019-5.685.006-6.919h.996v-1.75H12.667v1.751h1.323c-.016 1.234-.054 5.229.005 6.917-.011.386-.961 1.681-1.726 2.724a70.879 70.879 0 0 0-1.528 2.141c-1.563 2.298-3.075 4.99-3.722 6.143l-.173.305c-.665 1.174-.672 2.777-.021 3.897.496.849 1.291 1.317 2.235 1.317h20.153c.941 0 1.736-.469 2.23-1.317.655-1.121.648-2.725-.017-3.899zm-1.233 3.168c-.23.396-.562.6-.982.6H9.061c-.419 0-.751-.202-.981-.6-.394-.672-.381-1.729.029-2.453l.174-.312c.636-1.134 2.128-3.789 3.657-6.035.512-.756 1.029-1.46 1.499-2.101 1.335-1.821 2.031-2.808 2.006-3.626-.053-1.503-.026-4.999-.009-6.508h7.402c.016 1.51.045 5.005-.008 6.508-.028.819.669 1.805 2.004 3.626.469.641.986 1.345 1.5 2.101 1.527 2.246 3.02 4.901 3.656 6.035l.173.312c.411.723.423 1.781.03 2.453z" />
    <path d="M11.338 5.192v-.147H0v3.063h1.59v16.127c0 1.08.847 4.164 4.606 4.164.14 0 .263-.021.397-.03a2.652 2.652 0 0 1-1.099-1.052 3.472 3.472 0 0 1-.232-.502c-2.119-.479-2.191-2.558-2.191-2.581V8.109h5.735v9.775a51.768 51.768 0 0 1 1.482-2.158V8.109h1.592V5.192h-.542z" />
    <path d="M4.305 24.326c0 .189.115.878.72 1.27-.033-.752.126-1.528.493-2.176l.171-.304c.382-.679 1.069-1.899 1.886-3.256v-3.822h-3.27v8.288z" />
  </SvgIcon>