import { useContext } from "react";
import { InboxContext } from "@/contexts/InboxContext";
import { useLanguage } from "@/hooks/useLanguage";
import { useSession } from "next-auth/react";
import { UserRole, UserRoleEnum } from "@/enum/UserRoleEnum";
import { roleAccess, routesConfig } from "@/routes";
import { Route } from "@/interfaces/IRoute";
import { CopilotContext } from "@/contexts/CopilotContext";

export const useAppRoutes = () => {
  const { lang } = useLanguage();
  const { unreadQuestions } = useContext(InboxContext);
  const copilotContext = useContext(CopilotContext);
  const { sessions } = copilotContext || {};
  const { data: session } = useSession();
  
  const role = session?.config?.role as UserRole | UserRole[] | undefined;
  const userRoles: UserRole[] = role ? (Array.isArray(role) ? role : [role]) : [];

  const highestRole: UserRoleEnum | null = userRoles ? (
      userRoles.includes(UserRoleEnum.ADMIN)
      ? UserRoleEnum.ADMIN
      : userRoles.includes(UserRoleEnum.CURATOR)
      ? UserRoleEnum.CURATOR
      : UserRoleEnum.AGENT
  ) : null

  const routes = routesConfig(lang, Number(unreadQuestions?.length), sessions?.length || 0);

  const getRoleBasedRoutes = () => {
    if (userRoles.length === 0 || !highestRole) {
      return [];
    }

    const orderedRoutes: Route[] = [];

    roleAccess[highestRole].forEach((routeKey) => {
      if (!orderedRoutes.some((route) => route.url === routes[routeKey].url)) {
        orderedRoutes.push(routes[routeKey]);
      }
    });

    return orderedRoutes;
  };

  return { userRole: highestRole, routes, getRoleBasedRoutes };
};
