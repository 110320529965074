import { UserRole } from "@/enum/UserRoleEnum";
import { Route } from "@/interfaces/IRoute";

export const routesConfig = (lang: (key: string) => string, unreadQuestions: number, countSessions: number): { [key: string]: Route } => ({
  home: { 
    text: lang('menu.home'), 
    url: '/home', 
    icon: 'home', 
    navOnly: true 
  },
  inbox: { 
    text: lang('menu.inbox'), 
    url: '/inbox', 
    icon: 'inbox', 
    navOnly: false, 
    notification: unreadQuestions, 
    description: lang('menu.inbox.description') 
  },
  copilot: { 
    text: lang('menu.copilot'), 
    url: '/copilot', 
    icon: 'headset', 
    navOnly: false, 
    notification: countSessions, 
    description: lang('menu.copilot.description') 
  },
  brain: { 
    text: lang('menu.brain'), 
    url: '/brain/1', 
    icon: 'brain',
    navOnly: false,
    description: lang('menu.brain.description'),
    children: [
      { text: 'Listar Artigos', url: '/brain/1', icon: 'article' },
      { text: 'Upload de Arquivo', url: '/brain/file-upload/1', icon: 'upload' },
    ],
  },
  tickets: {
    text: lang('menu.tickets'), 
    url: '/tickets/1',
    icon: 'ticket', 
    description: lang('menu.tickets.description'),
    navOnly: false 
  },
  dashboard: { 
    text: lang('menu.dashboard'), 
    url: '/dashboard', 
    icon: 'dashboard', 
    description: lang('menu.dashboard.description'),
    navOnly: false 
  },
  databases: { 
    text: lang('menu.databases'), 
    url: '/databases/1', 
    icon: 'database', 
    description: lang('menu.databases.description'),
    navOnly: false 
  },
  agents: { 
    text: lang('menu.agents'), 
    url: '/agents-ai', 
    icon: 'agents', 
    description: lang('menu.agents.description'),
    navOnly: false 
  },
  testing: { 
    text: lang('menu.testingtool'), 
    url: '/testing-tool', 
    icon: 'testingtool', 
    description: lang('menu.testingtool.description'),
    navOnly: false 
  },
});

export const roleAccess: { [key in UserRole]: (keyof ReturnType<typeof routesConfig>)[] } = {
  admin: ['home', 'copilot', 'brain', 'tickets', 'dashboard', 'databases'],
  curator: ['home', 'copilot', 'brain', 'tickets', 'dashboard'],
  agent: ['home', 'copilot', 'tickets'],
};

export const unrestrictedRoutes = ['/profile', '/change_password'];